//
//
//
//
//
//
//
//
//
import Profile from '@modules/cash/components/deposit/Profile';
import CashWrapper from "./CashWrapper";
import Header from "./Header";
export default {
  name: 'Account',
  components: {
    Profile: Profile,
    CashWrapper: CashWrapper,
    Header: Header
  }
};